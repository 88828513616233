import * as React from "react";
import type { HeadFC } from "gatsby";

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 16px)",
};
const headingStyles = {
  maxWidth: 780,
  textAlign: "center",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <h1 style={headingStyles}>
        Prepárate para encontrar el mejor merch para tus próximos eventos en{" "}
        <a href="https://styleup.store">styleup.store</a> 😎
      </h1>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>StyleUp Store</title>;
